import React, { Suspense } from 'react';

import { ProtectedRoute } from 'routes/ProtectedRoute';

import { PageLoader } from '../components';

export const authWrapper = (component, state = 'auth', allowedRoles = []) => (
  <Suspense fallback={<PageLoader />}>
    <ProtectedRoute state={state} allowedRoles={allowedRoles}>
      {component}
    </ProtectedRoute>
  </Suspense>
);
