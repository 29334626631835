import React from 'react';

import { suspenseWrapper } from 'utils/suspenseWrapper';


export const networkRoute = [
  {
    path: '',
    async lazy() {
      const  { Network } = await import("../pages/network/All/Network");
      return { Component: () => suspenseWrapper(<Network />) };
    }
  }
];
