import React from 'react';

import { suspenseWrapper } from 'utils/suspenseWrapper';

export const documentTemplateRoute = [
    {
        path: '',
        async lazy() {
            const { DocumentTemplate } = await import("../pages/documentTemplate/All/DocumentTemplate");
            return { Component: () => suspenseWrapper(<DocumentTemplate />) };
        }
    },
    {
        path: 'proposals',
        async lazy() {
          const  { Proposals } = await import("../pages/products/Proposals/Proposals");
          return { Component: () => suspenseWrapper(<Proposals />) };
        }
      }
];