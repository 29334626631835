export const SCREEN = {
  COMPLETED: 'Completed',
  DATA_COLLECTION: 'Data Collection',
  DOWNLOAD_POLICY: 'Download Policy',
  PAYMENT: 'Payment',
  PREPARE_APPLICATION: 'Prepare Application',
  REVIEW_APPLICATION: 'Review Quote',
  SELECT_PRODUCT: 'Select Product',
  SELECT_QUOTE: 'Select Indication',
  SIGNATURE: 'Signature'
};
