import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

// Redux slices
import { modalSlice, loaderSlice, currentEditorSlice } from './modules';
// Redux API Queries/Mutations
import { configApi, configSlice } from './modules/config';
import {
  loginApi,
  resetPasswordApi,
  signUpApi,
  forgotPasswordApi
} from './modules/auth';
import { productApi } from './modules/products';
import { companyProductApi } from './modules/companyProducts';
import { commissionApi } from './modules/commission';
import { proposalApi } from './modules/proposal';
import { companyApi } from './modules/company';
import { userApi } from './modules/user';
import { applicationApi } from './modules/application';
import { quoteApi } from './modules/quote';
import { shareApplicationApi } from './modules/shareApplication';
import { distributionsApi } from './modules/distribution';
import { documentTemplateApi } from './modules/documentTemplate';

const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: {
    [modalSlice.name]: modalSlice.reducer,
    [loaderSlice.name]: loaderSlice.reducer,
    [currentEditorSlice.name]: currentEditorSlice.reducer,
    [configSlice.name]: configSlice.reducer,
    [configApi.reducerPath]: configApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
    [forgotPasswordApi.reducerPath]: forgotPasswordApi.reducer,
    [signUpApi.reducerPath]: signUpApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [companyProductApi.reducerPath]: companyProductApi.reducer,
    [commissionApi.reducerPath]: commissionApi.reducer,
    [proposalApi.reducerPath]: proposalApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [applicationApi.reducerPath]: applicationApi.reducer,
    [quoteApi.reducerPath]: quoteApi.reducer,
    [shareApplicationApi.reducerPath]: shareApplicationApi.reducer,
    [distributionsApi.reducerPath]: distributionsApi.reducer,
    [documentTemplateApi.reducerPath]: documentTemplateApi.reducer
  },
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare()
      .prepend(listenerMiddleware.middleware)
      .concat(
        configApi.middleware,
        loginApi.middleware,
        signUpApi.middleware,
        resetPasswordApi.middleware,
        forgotPasswordApi.middleware,
        productApi.middleware,
        companyProductApi.middleware,
        commissionApi.middleware,
        proposalApi.middleware,
        companyApi.middleware,
        userApi.middleware,
        applicationApi.middleware,
        quoteApi.middleware,
        shareApplicationApi.middleware,
        distributionsApi.middleware,
        documentTemplateApi.middleware
      ),
  devTools: true
});

// TODO: need to remove all listeners and move to RTK Queries
// add all listeners
// [...configListeners].map((sl) => {
//   listenerMiddleware.startListening(sl);

//   return true;
// });

// Initialize RTK listeners
setupListeners(store.dispatch);
