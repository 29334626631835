import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

const InputTextField = styled(OutlinedInput)({
  '& .MuiFormHelperText-root': {
    color: 'red',
    marginLeft: '2px'
  }
});

export function PasswordFiled({
  register,
  name,
  label,
  validate,
  helperText,
  error
}) {
  const [values, setValues] = useState({
    password: '',
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  return (
    <>
      <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
      <InputTextField
        id="outlined-adornment-password"
        error={error}
        type={values.showPassword ? 'text' : 'password'}
        {...register(name, validate)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword} edge="end">
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        sx={{
          marginTop: '3px',
          marginLeft: '2px',
          color: 'red'
        }}
      >
        {helperText}
      </Typography>
    </>
  );
}
