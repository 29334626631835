import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const companyProductApi = createApi({
  reducerPath: 'companyProductApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getAuthrizedCompanyDetails: builder.query({
      query: (payload) => ({
        url: `/company-product/list?businessType=${payload.businessType}&companyId=${payload.companyId}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),

    authrizedCompanyDetail: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company-product/product-company-detail?productCompanyId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    addAuthrizedCompany: builder.mutation({
      query: (payload) => ({
        url: '/company-product/add-company-product',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    updateAuthrizedCompany: builder.mutation({
      query: (payload) => ({
        url: '/company-product/update-company-product',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    })
  })
});

export const {
  useAddAuthrizedCompanyMutation,
  useGetAuthrizedCompanyDetailsQuery,
  useAuthrizedCompanyDetailQuery,
  useUpdateAuthrizedCompanyMutation
} = companyProductApi;
