/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Grow,
  DialogActions,
  DialogContent,
  Dialog,
  Typography
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useAwsS3 } from 'hooks/useAwsS3';
import { socket } from 'utils/http';
import { useUpdateCurrentEditorMutation } from 'redux/modules/shareApplication';
import { useAuth } from 'hooks/useAuth';
import { selectEditorState, setCurrentEditor } from 'redux/modules';
import { AUTH_ROLES } from 'constant/authRole';
import { SCREEN } from 'constant/screenConstant';

import { FullModalTitle } from './FullModalTitle';

const CustomModal = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': { zIndex: '1' },
  '& .MuiDialogTitle-root button': { marginTop: '-8px' },
  '& .MuiDialogContent-root': {
    padding: `${theme.spacing(3)}!important`,
    margin: 0,
    background: '#F6F7F8'
  },
  '& .MuiDialogActions-root': { padding: theme.spacing(3), zIndex: '1' },
  '& .MuiPaper-root': {
    borderRadius: 0,
    margin: 0,
    height: '100%',
    maxHeight: '100%',
    width: '100%',
    maxWidth: '100%'
  }
}));

const Transition = React.forwardRef((props, ref) => (
  <Grow ref={ref} {...props} />
));

export function FullModal({
  open,
  title,
  onClose,
  content,
  action,
  backAction,
  currentScreen,
  refetchQuoteDetails,
  activeStep,
  ...props
}) {
  const { isLoading } = useAwsS3();

  const [searchParams] = useSearchParams();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { currentEditor } = useSelector(selectEditorState);

  const applicationId = searchParams.get('applicationId');

  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket && applicationId) {
      socket.on(`FORM-CONTROL-${applicationId}`, () => {
        refetchQuoteDetails();
        dispatch(setCurrentEditor(currentScreen));
      });
    }

    return () => {
      socket.off(`FORM-CONTROL-${applicationId}`);
    };
  }, [socket, applicationId]);

  const [updateEditor] = useUpdateCurrentEditorMutation(
    {},
    {
      pollingInterval: 3000,
      refetchOnMountOrArgChange: true,
      skip: false
    }
  );

  const handleChangeEditor = () => {
    const data = {
      applicationId,
      currentEditor: AUTH_ROLES.AGENCY_ADMIN
    };
    const payload = {
      data,
      token: user.token
    };
    updateEditor(payload).unwrap();
  };

  return (
    <CustomModal
      onClose={onClose}
      TransitionComponent={Transition}
      open={open}
      {...props}
    >
      {/* {user.role.slug === 'AGENCY_ADMIN' && ( */}
      <FullModalTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </FullModalTitle>
      {/* )} */}
      {currentEditor === AUTH_ROLES.AGENCY_CUSTOMER &&
        user?.role?.slug !== AUTH_ROLES.AGENCY_CUSTOMER &&
        currentScreen === SCREEN.DATA_COLLECTION && (
          <Box
            width="100%"
            height={100}
            bgcolor="#FFFAF1"
            borderBottom={1}
            borderColor="#F2E4CA"
            display="grid"
            sx={{ placeItems: 'center' }}
          >
            <Typography
              fontWeight="800"
              width="100%"
              textAlign="center"
              color="#B27417"
            >
              Attention:
              <span style={{ fontWeight: 'normal' }}>
                &nbsp; Customer is currently editing the application. Please
                &nbsp;
                <span
                  style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                  onClick={handleChangeEditor}
                >
                  Click here
                </span>
                &nbsp;to enter in Edit Mode
              </span>
            </Typography>
          </Box>
        )}

      {currentEditor === AUTH_ROLES.AGENCY_ADMIN &&
        user?.role?.slug === AUTH_ROLES.AGENCY_CUSTOMER &&
        currentScreen === SCREEN.DATA_COLLECTION && (
          <Box
            width="100%"
            height={100}
            bgcolor="#FFFAF1"
            borderBottom={1}
            borderColor="#F2E4CA"
            display="grid"
            sx={{ placeItems: 'center' }}
          >
            <Typography
              fontWeight="800"
              width="100%"
              textAlign="center"
              color="#B27417"
            >
              Attention:
              <span style={{ fontWeight: 'normal' }}>
                &nbsp; The Agent is currently editing the application. Please
                wait for the Agent to restore your edit access.
              </span>
            </Typography>
          </Box>
        )}

      <DialogContent sx={{ margin: '24px 0' }}>{content}</DialogContent>
      {!isLoading && activeStep !== 0 && (
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            boxShadow: '0 -2px 5px #ccc'
          }}
        >
          {backAction}
          <Box sx={{ flex: '1 1 auto' }} />
          {action}
        </DialogActions>
      )}
    </CustomModal>
  );
}
