import axios from 'axios';
import { io } from 'socket.io-client';

import { SOCKET_BASE_URL, SOCKET_SITE_PATH } from '../constant/apiConstant';

export const httpGet = (state, url, params = {}, headers = {}) => {
  const newHeaders = headers;

  if (state) {
    newHeaders['X-API-KEY'] = state.apiKey;
  }
  const axiosCreate = axios.create({ headers: newHeaders });

  return axiosCreate.get(url, { params });
};

export const httpPost = (state, url, params = {}, headers = {}) => {
  const newHeaders = headers;

  if (state) {
    newHeaders['X-API-KEY'] = state.apiKey;
  }
  const axiosCreate = axios.create({ headers: newHeaders });

  return axiosCreate.post(url, params);
};

export const socket = io(SOCKET_BASE_URL, {
  path: SOCKET_SITE_PATH,
  autoConnect: false
  // transports: ['websocket'],
});
