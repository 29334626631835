export const path = {
  app: {
    login: '/login',
    signUp: '/Sign-up',
    signUpSuccess: '/sign-up-success',
    resetPassword: '/reset-password',
    forgotPassword: '/forgot-password',
    checkEmail: '/check-email',
    thankYou:'/thank-you'
  },
  products: {
    root: '/products',
    allApplications:'/products/applications/all',
    applications: '/products/product-applications',
    carrierApplications: '/products/carrier-applications'
  },
  agencies: {
    root: '/agencies',
    agents: '/agencies/agents',
    documents: '/agencies/documents',
    authAgencies: '/agencies/authorized-agencies',
    commissions: '/agencies/commissions'
  },
  mgas: {
    root: '/mgas',
    rating: '/mgas/rating-systems',
    mgaUsers: '/mgas/mga-users',
    authorizedCarrier: '/mgas/authorized-carriers',
    authMgas: '/mgas/authorized-mga'
  },
  rating: {
    newRating: '/mgas/rating/create',
    updateRating: '/mgas/rating/edit'
  },
  carriers: { root: '/carriers' },
  distribution: {
    root: '/distribution',
    binding: '/distribution/binding',
    policy: '/distribution/policy',
    mgaSubmission: '/distribution/mga-submission',
    payment: '/distribution/payment',
    applicationForms: '/distribution/application-forms',
    quoteIndication: '/distribution/quote-indication'
  },
  users: { root: '/users' },
  quote: { root: '/quote' },
  agents: { root: '/agents' },
  network: { root: '/network' },
  documentTemplate: { 
    root: '/document-template',     
    proposals: '/document-template/proposals'
}
};
