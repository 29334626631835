import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const documentTemplateApi = createApi({
  reducerPath: 'documentTemplateApi',
  tagTypes: ['POST'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getDocumentTemplates: builder.query({
      query: (payload) => ({
        url: `/document-template/get-document-templates?limit=${payload.limit}&action=${payload.action}&LastEvaluatedKey=${payload.lastEvaluatedKey}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getSingleTemplate: builder.query({
      query: (payload) => ({
        url: `document-template/get-single-document-template?documentTemplateId=${payload.documentTemplateId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    updateSingleTemplate: builder.mutation({
      query: (payload) => ({
        url: '/document-template/update-single-document-template',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    addSingleTemplate: builder.mutation({
      query: (payload) => ({
        url: '/document-template/add-document-template',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    })
  })
});
export const {
  useGetDocumentTemplatesQuery,
  useGetSingleTemplateQuery,
  useUpdateSingleTemplateMutation,
  useAddSingleTemplateMutation
} = documentTemplateApi;
