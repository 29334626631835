import { createSlice } from '@reduxjs/toolkit';

export const initialConfigState = { language: 'en' };

// Demo Redux Slice
export const configSlice = createSlice({
  name: 'appConfig',
  initialState: initialConfigState,
  reducers: {}
});

export const selectLanguageState = (state) => state?.appConfig?.language;
