import React from 'react';

import { suspenseWrapper } from 'utils/suspenseWrapper';


export const usersRoute = [
  {
    path: '',
    async lazy() {
      const  { Users } = await import("../pages/users/All/Users");
      return { Component: () => suspenseWrapper(<Users />) };
    }
  }
];
