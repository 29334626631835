/* eslint-disable react/button-has-type */
import React from 'react';
import { Provider } from 'react-redux';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { theme } from 'theme';
import { appRouter } from 'routes/appRoute';
import { AuthProvider } from 'context/AuthProvider';

// import useChat from 'hooks/useChat';
// import { useGetConfigDataQuery } from 'redux/modules/config';
import { store } from './redux';

function InnerApp() {
  // const { data: configData } = useGetConfigDataQuery();
  // useEffect(() => {
  //   if (configData) {
  //     const script = document.createElement('script');

  //     script.src = `//js.hs-scripts.com/${configData?.chatWidget?.API_KEY}.js`;
  //     script.async = true;

  //     document.body.appendChild(script);
  //   }
  // }, [configData]);
  return <RouterProvider router={appRouter} />;
}

function App() {
  return (
    <React.StrictMode>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <InnerApp />
          </Provider>
        </ThemeProvider>
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;
