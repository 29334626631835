/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Lottie from 'react-lottie';

import animation from 'assets/Main.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation
};

const AnimatedLoader = () => (
  <Lottie options={defaultOptions} height={150} width={150} />
);

export default AnimatedLoader;
