import { createSlice } from '@reduxjs/toolkit';

export const initialLoaderState = { loading: false };

// Loading Redux Slice
export const loaderSlice = createSlice({
  name: 'loader',
  initialState: initialLoaderState,
  reducers: {
    setLoading(state, action) {
      return action.payload;
    }
  }
});

export const { setLoading } = loaderSlice.actions;

export const selectLoaderState = (state) => state.loader;
