import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const userApi = createApi({
  reducerPath: 'userApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (payload) => ({
        url: `/user/user-list?${
          payload.businessType ? `businessType=${payload.businessType}&` : ''
        }${payload.companyId ? `companyId=${payload.companyId}&` : ''}limit=${
          payload.limit === -1 ? 200 : payload.limit
        }&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),

    UserDetail: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/user/user-detail?userId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    addUser: builder.mutation({
      query: (payload) => ({
        url: '/user/add-user',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    updateUser: builder.mutation({
      query: (payload) => ({
        url: '/user/update-user',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),

    verifyApplicationEmail: builder.mutation({
      query: (payload) => ({
        url: '/user/verify-application-email',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
        }
      }),
      invalidatesTags: ['Post']
    }),

    verifyCustomerToken: builder.mutation({
      query: (payload) => ({
        url: '/user/verify-application-customer',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
        }
      }),
      invalidatesTags: ['Post']
    }),
    verifyConsumerCustomerToken: builder.mutation({
      query: (payload) => ({
        url: '/user/verify-consumer-customer-token',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
        }
      }),
      invalidatesTags: ['Post']
    })
  })
});

export const {
  useAddUserMutation,
  useGetUsersQuery,
  useUserDetailQuery,
  useUpdateUserMutation,
  useVerifyApplicationEmailMutation,
  useVerifyCustomerTokenMutation,
  useVerifyConsumerCustomerTokenMutation
} = userApi;
