import React, { useEffect, useState } from 'react';
import { Chip } from '@mui/material';

export function StatusChip({ color, label, borderRadius }) {
  const [statusColor, setstatusColor] = useState('#E6FFF3');

  useEffect(() => {
    switch (color) {
      case 'success':
        setstatusColor('#E6FFF3');
        break;
      case 'warning':
        setstatusColor('#FFFAEB');
        break;
      case 'error':
        setstatusColor('#FFF3F3');
        break;
      case 'transparent':
        setstatusColor('#0000ffff');
        break;
      default:
        break;
    }
  }, [color]);

  return (
    <Chip
      sx={{
        textTransform: 'capitalize',
        background: statusColor,
        border: 'none',
        borderRadius: borderRadius || '5px',
        padding: '2px'
      }}
      variant="outlined"
      label={label}
      color={color}
      size="small"
    />
  );
}
