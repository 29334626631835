import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';

import { useGetUniqueKey } from 'hooks/useGetUniqueKey';

import { CustomTableToolbar } from './CustomTableToolbar';
import { CustomPaginationActionsTable } from './CustomTablePagination';

export const CustomTable = React.memo(
  ({
    header,
    rows,
    totalRecords,
    onPageChange,
    onActionBtnClick,
    hideToolBox,
    hidePagination,
    noDataMessage,
    showSearchAndFilter,
    handleSearch
  }) => {
    const getUniqueKey = useGetUniqueKey();
    return (
      <Paper elevation={1} sx={{ mb: 2 }}>
        {!hidePagination && (
          <CustomTableToolbar
            onActionBtnClick={onActionBtnClick}
            totalRecords={totalRecords}
            visibleRecords={rows.length}
            hideToolBox={hideToolBox}
            showSearchAndFilter={showSearchAndFilter}
            handleSearch={handleSearch}
          />
        )}
        <TableContainer sx={{ padding: hidePagination ? '20px' : '0px 20px' }}>
          <Table
            sx={{ minWidth: 650, mb: rows.length ? 0 : 4 }}
            aria-label="table"
          >
            <TableHead>
              <TableRow
                sx={{
                  background: '#F5F6F8',
                  borderRadius: '4px',
                  th: { fontWeight: 'bold' }
                }}
              >
                {header.map((head) => (
                  <TableCell
                    style={{ color: '#6B727D', fontSize: '14px' }}
                    key={getUniqueKey({ head })}
                  >
                    {head.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {rows.length ? (
              <TableBody>
                {rows.map((row, rowIndex) => (
                  <TableRow key={getUniqueKey({ row, rowIndex })}>
                    {header.map((tableHead, index) => (
                      <TableCell
                        key={getUniqueKey({ data: row[tableHead.id], index })}
                        align={tableHead.align ? tableHead.align : 'left'}
                      >
                        {row[tableHead.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={5}
                    style={{
                      textAlign: 'center',
                      fontWeight: '700'
                    }}
                  >
                    {noDataMessage}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            <TableFooter>
              <TableRow sx={{ display: hidePagination && 'none' }}>
                <CustomPaginationActionsTable
                  totalRecords={totalRecords}
                  onPageChange={onPageChange}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
);
