import { createSlice } from '@reduxjs/toolkit';

export const initialEditorState = { currentEditor: 'AGENCY_ADMIN' };

// Loading Redux Slice
export const currentEditorSlice = createSlice({
  name: 'editor',
  initialState: initialEditorState,
  reducers: {
    setCurrentEditor: (state, action) => ({
      ...state,
      currentEditor: action.payload
    })
  }
});

export const { setCurrentEditor } = currentEditorSlice.actions;

export const selectEditorState = (state) => state.editor;
