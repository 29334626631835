export const whiteColor = '#FFFFFF';
export const blackColor = '#0B1524';

export const lightGreyColor = '#F5F6F7';
export const darkGreyColor = '#5B616B';
export const mediumGreyColor = '#00000030';

export const primaryColor = '#3487F3';
export const primaryHoverColor = '#2D2975';

export const secondaryColor = '#ffffff';
export const secondaryHoverColor = '#7D828B';
