import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const proposalApi = createApi({
  reducerPath: 'ProposalApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getProposalDetails: builder.query({
      query: (payload) => ({
        url: `/proposal-template/proposal-template-list?limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),

    ProposalDetail: builder.query({
      query: (payload) => ({
        url: `/proposal-template/proposal-template-detail?proposalTemplateId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    addProposal: builder.mutation({
      query: (payload) => ({
        url: '/proposal-template/add-proposal-template',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    updateProposal: builder.mutation({
      query: (payload) => ({
        url: '/proposal-template/update-proposal-template',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    })
  })
});

export const {
  useAddProposalMutation,
  useGetProposalDetailsQuery,
  useProposalDetailQuery,
  useUpdateProposalMutation
} = proposalApi;
