import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Toolbar,
  Typography
  // IconButton,
  // Tooltip,
} from '@mui/material';
// import FilterListIcon from '@mui/icons-material/FilterList';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const filters = [
  {
    value: 'AGENCY',
    label: 'Agency'
  },
  {
    value: 'PRODUCT',
    label: 'Product'
  }
];

export const CustomTableToolbar = (props) => {
  const { pathname } = useLocation();
  const {
    // actionBtnLabel,
    actionBtnIcon,
    onActionBtnClick,
    totalRecords,
    visibleRecords,
    hideToolBox,
    showSearchAndFilter,
    handleSearch
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const { t } = useTranslation();

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleReset = () => {
    setFilterValue('');
    setSearchValue('');
    handleSearch();
  };

  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 32px !important',
        width: '100%'
      }}
    >
      <Typography
        style={{ color: '#6B727D', fontSize: '14px' }}
        id="tableTitle"
        component="div"
        variant="subtitle2"
        width="20%"
      >
        {t('tableHead.showing')} <strong>{visibleRecords}</strong> of{' '}
        <strong>{totalRecords}</strong>
      </Typography>

      <Box
        sx={{
          display: hideToolBox ? 'none' : 'flex',
          alignItems: 'center'
        }}
      >
        <Button
          variant="primary"
          size="small"
          endIcon={actionBtnIcon}
          sx={{
            mr: 1,
            color: '#fff',
            fontWeight: '600'
          }}
          onClick={onActionBtnClick}
        >
          {pathname === '/quote'
            ? t('buttonLabel.newQuoteRequest')
            : t('buttonLabel.addNew')}
        </Button>

        {/* <Tooltip title="Filter list">
          <>
            <IconButton>
              <FilterListIcon />
            </IconButton>
            <Typography variant="subtitle1" component="h6">
              {t('buttonLabel.filter')}
            </Typography>
          </>
        </Tooltip> */}
      </Box>
      {showSearchAndFilter && (
        <Grid container width="100%" gap={4}>
          <Grid item sx={{ width: 240 }}>
            <FormControl fullWidth variant="outlined">
              <TextField
                size="small"
                select
                label={t('formLabel.filterBy')}
                value={filterValue}
                onChange={(data) => {
                  setFilterValue(data.target.value);
                }}
                variant="outlined"
              >
                {filters?.map((option) => (
                  <MenuItem
                    key={`company-${option.value}`}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item sx={{ width: 240 }}>
            <FormControl fullWidth variant="outlined">
              <TextField
                label={t('formLabel.searchBy')}
                onChange={handleSearchChange}
                variant="outlined"
                size="small"
                value={searchValue}
                placeholder="Search By"
              />
            </FormControl>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              disabled={searchValue.length < 2}
              onClick={() => handleSearch(searchValue, filterValue)}
            >
              {t('buttonLabel.search')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleReset}>
              {t('buttonLabel.clearSearch')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

CustomTableToolbar.propTypes = {
  // actionBtnLabel: PropTypes.string,
  actionBtnIcon: PropTypes.node,
  onActionBtnClick: PropTypes.func,
  totalRecords: PropTypes.number,
  visibleRecords: PropTypes.number
};

CustomTableToolbar.defaultProps = {
  // actionBtnLabel: 'buttonLabel.addNew',
  actionBtnIcon: <AddIcon />,
  onActionBtnClick: () => {},
  totalRecords: 0,
  visibleRecords: 0
};


