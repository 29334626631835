import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const quoteApi = createApi({
  reducerPath: 'quoteApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getQuotes: builder.query({
      query: (payload) => ({
        url: `/application/application-list?limit=${
          payload.limit === -1 ? 200 : payload.limit
        }&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),

    searchCustomer: builder.mutation({
      query: (payload) => ({
        url: '/application/search-company',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    addInitiateApplication: builder.mutation({
      query: (payload) => ({
        url: '/application/initiate-application',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    quoteDetail: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `application/get-single-application?applicationId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    collectData: builder.mutation({
      query: (payload) => ({
        url: '/application/get-proposals',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    carrierApplicationCollectData: builder.mutation({
      query: (payload) => ({
        url: '/application/carrier-application/submit-carrier-application',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    proposalUIData: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/application/get-proposal-ui-data?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    proposalList: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `application/submission-application?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    finalProposalList: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `application/carrier-application/get-final-quote?applicationId=${payload.applicationId}&submissionResponseId=${payload.submissionResponseId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    preBindApplication: builder.mutation({
      query: (payload) => ({
        url: '/application/pre-bind-application',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    bindApplication: builder.mutation({
      query: (payload) => ({
        url: '/application/bind-application',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    getSignProposal: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/application/get-sign-proposal-url?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response
    }),

    uploadPolicy: builder.mutation({
      query: (payload) => ({
        url: '/application/upload-policy',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    getEmailTemplate: builder.query({
      keepUnusedDataFor: 30,
      query: (payload) => ({
        url: `/application/get-email-template?applicationId=${payload.applicationId}&email=${payload.email}&customerName=${payload.customerName}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    sendToCustomer: builder.mutation({
      query: (payload) => ({
        url: '/application/send-to-customer',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    getPaymentDetails: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/application/get-payment-details?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    postPayment: builder.mutation({
      query: (payload) => ({
        url: '/application/payment',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    uploadSignedDocs: builder.mutation({
      query: (payload) => ({
        url: '/application/upload-signed-docs',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    verifyPayment: builder.mutation({
      query: (payload) => ({
        url: '/application/verify-payment-details',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    getPaymentList: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/application/get-post-payment-details?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    updateActiveScreen: builder.mutation({
      query: (payload) => ({
        url: '/application/update-active-screen',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    })
  })
});

export const {
  useSearchCustomerMutation,
  useAddInitiateApplicationMutation,
  useGetQuotesQuery,
  useQuoteDetailQuery,
  useCollectDataMutation,
  useProposalUIDataQuery,
  useProposalListQuery,
  useFinalProposalListQuery,
  useBindApplicationMutation,
  useGetSignProposalQuery,
  useUploadPolicyMutation,
  useGetEmailTemplateQuery,
  useSendToCustomerMutation,
  useGetPaymentDetailsQuery,
  usePostPaymentMutation,
  useVerifyPaymentMutation,
  useGetPaymentListQuery,
  useUpdateActiveScreenMutation,
  useUploadSignedDocsMutation,
  usePreBindApplicationMutation,
  useCarrierApplicationCollectDataMutation
} = quoteApi;
