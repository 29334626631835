import { blackColor, darkGreyColor } from './colors';

export const caption = () => ({
  font: 'normal normal 300 14px/20px Roboto',
  color: darkGreyColor,
  lineHeight: '18px',
  mt: 1
});

export const subtitle1 = () => ({
  font: 'normal normal 300 14px/20px Roboto',
  color: blackColor,
  lineHeight: '20px',
  mt: 1,
  fontWeight: 600
});

export const h6 = () => ({
  font: 'normal normal 300 14px/20px Roboto',
  color: blackColor,
  lineHeight: '20px',
  fontSize: '1.2rem',
  fontWeight: 800
});
