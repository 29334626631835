import React from 'react';

import { suspenseWrapper } from 'utils/suspenseWrapper';

export const productsRoute = [
  {
    path: '',
    async lazy() {
      const  { ProductsPage } = await import("../pages/products/All/Products");
      return { Component: () => suspenseWrapper(<ProductsPage />) };
    }
  },
  {
    path: 'applications/all',
    async lazy() {
      const  { AllApplications } = await import("../pages/products/Applications/AllApplications");
      return { Component: () => suspenseWrapper(<AllApplications />) };
    },
    children: [
      {
        path: 'create',
        async lazy() {
          const  { NewApplication } = await import("../pages/products/Applications/NewApplication");
          return { Component: () => suspenseWrapper(<NewApplication />) };
        }
      },
      {
        path: 'edit/:id',
        async lazy() {
          const  { NewApplication } = await import("../pages/products/Applications/NewApplication");
          return { Component: () => suspenseWrapper(<NewApplication />) };
        }
      }
    ]
  },
  {
    path: 'product-applications',
    async lazy() {
      const  { ApplicationsPage } = await import("../pages/products/Applications/Applications");
      return { Component: () => suspenseWrapper(<ApplicationsPage />) };
    },
    children: [
      {
        path: 'create',
        async lazy() {
          const  { NewApplication } = await import("../pages/products/Applications/NewApplication");
          return { Component: () => suspenseWrapper(<NewApplication />) };
        }
      },
      {
        path: 'edit/:id',
        async lazy() {
          const  { NewApplication } = await import("../pages/products/Applications/NewApplication");
          return { Component: () => suspenseWrapper(<NewApplication />) };
        }
      }
    ]
  },
  {
    path: 'carrier-applications',
    async lazy() {
      const  { CarrierApplicationsPage } = await import("../pages/products/Applications/CarrierApplications");
      return { Component: () => suspenseWrapper(<CarrierApplicationsPage />) };
    },
    children: [
      {
        path: 'create',
        async lazy() {
          const  { NewCarrierApplication } = await import("../pages/products/Applications/NewCarrierApplication");
          return { Component: () => suspenseWrapper(<NewCarrierApplication />) };
        }
      },
      {
        path: 'edit/:id',
        async lazy() {
          const  { NewCarrierApplication } = await import("../pages/products/Applications/NewCarrierApplication");
          return { Component: () => suspenseWrapper(<NewCarrierApplication />) };
        }
      }
    ]
  }

];
