/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-cycle */
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { suspenseWrapper } from 'utils/suspenseWrapper';
import { authWrapper } from 'utils/authWrapper';
import { path } from 'constant/pathLinksConstant';
import {
  AgencyAdminAccessRoles,
  AgencyEmpAccessRoles,
  superAdminAccessRoles
} from 'constant/authRole';
import ErrorPage from 'pages/404';

import { documentTemplateRoute } from './documentTemplateRoute';
import { productsRoute } from './productsRoute';
import { agenenciesRoute } from './agenciesRoute';
import { mgasRoute } from './mgasRoute';
import { carriersRoute } from './carriersRoute';
import { quotesRoute } from './quotesRoute';
import { distributionRoute } from './distributionsRoute';
import { usersRoute } from './usersRoute';
import { agentsRoute } from './agentsRoute';
import { networkRoute } from './networkRoute';

export const appRouter = createBrowserRouter([
  {
    path: '/',
    async lazy() {
      const { LoginPage } = await import('../pages/auth/Login/Login');
      return { Component: () => authWrapper(<LoginPage />, 'unAuth') };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.login,
    async lazy() {
      const { LoginPage } = await import('../pages/auth/Login/Login');
      return { Component: () => authWrapper(<LoginPage />, 'unAuth') };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.forgotPassword,
    async lazy() {
      const { ForgotPassword } = await import(
        '../pages/auth/ForgotPassword/ForgotPassword'
      );
      return { Component: () => suspenseWrapper(<ForgotPassword />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.signUp,
    async lazy() {
      const { SignUpPage } = await import('../pages/auth/SignUp/SignUp');
      return { Component: () => suspenseWrapper(<SignUpPage />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.signUpSuccess,
    async lazy() {
      const { SuccessRegistrationPage } = await import(
        '../pages/auth/SignUp/SuccessRegistration'
      );
      return { Component: () => suspenseWrapper(<SuccessRegistrationPage />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.app.checkEmail,
    async lazy() {
      const { CheckEmail } = await import('../pages/auth/CheckEmail');
      return { Component: () => suspenseWrapper(<CheckEmail />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: '/verify-user-token',
    async lazy() {
      const { VerifyUserToken } = await import('../pages/VerifyUserToken');
      return { Component: () => suspenseWrapper(<VerifyUserToken />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: '/verify-consumer-customer',
    async lazy() {
      const { VerifyConsumerCustomer } = await import('../pages/VerifyConsumerCustomer');
      return { Component: () => suspenseWrapper(<VerifyConsumerCustomer />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: '/verify-customer',
    async lazy() {
      const { VerifyCustomer } = await import('../pages/VerifyCustomer');
      return { Component: () => suspenseWrapper(<VerifyCustomer />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.thankYou,
    async lazy() {
      const { ThankYou } = await import('../pages/quotes/ThankYou');
      return { Component: () => suspenseWrapper(<ThankYou />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.resetPassword,
    async lazy() {
      const { ResetPassword } = await import('../pages/auth/ResetPassword');
      return { Component: () => suspenseWrapper(<ResetPassword />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.products.root,
    async lazy() {
      const { ProductsPage } = await import('../pages/products');
      return {
        Component: () =>
          authWrapper(<ProductsPage />, 'auth', superAdminAccessRoles)
      };
    },
    children: productsRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.agencies.root,
    async lazy() {
      const { AgenciesPage } = await import('../pages/agencies');
      return {
        Component: () =>
          authWrapper(<AgenciesPage />, 'auth', superAdminAccessRoles)
      };
    },
    children: agenenciesRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.mgas.root,
    async lazy() {
      const { MgasPage } = await import('../pages/mgas');
      return {
        Component: () =>
          authWrapper(<MgasPage />, 'auth', superAdminAccessRoles)
      };
    },
    children: mgasRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.rating.newRating,
    async lazy() {
      const { NewRatingSystem } = await import(
        '../pages/mgas/RatingSystems/NewRatingSystem'
      );
      return { 
        Component: () =>
        authWrapper(<NewRatingSystem />, 'auth', superAdminAccessRoles)
    
        // Component: () => <NewRatingSystem /> 
      };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.rating.updateRating,
    async lazy() {
      const { NewRatingSystem } = await import(
        '../pages/mgas/RatingSystems/NewRatingSystem'
      );
      return { Component: () => authWrapper(<NewRatingSystem />, 'auth', superAdminAccessRoles)};
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.carriers.root,
    async lazy() {
      const { CarriersPage } = await import('../pages/carriers');
      return {
        Component: () =>
          authWrapper(<CarriersPage />, 'auth', superAdminAccessRoles)
      };
    },
    children: carriersRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.quote.root,
    async lazy() {
      const { QuotesPage } = await import('../pages/quotes');
      return {
        Component: () =>
          authWrapper(<QuotesPage />, 'auth', [
            ...superAdminAccessRoles,
            ...AgencyAdminAccessRoles,
            ...AgencyEmpAccessRoles
          ])
      };
    },
    children: quotesRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.distribution.root,
    async lazy() {
      const { DistributionPage } = await import('../pages/distribution');
      return {
        Component: () =>
          authWrapper(<DistributionPage />, 'auth', superAdminAccessRoles)
      };
    },
    children: distributionRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.network.root,
    async lazy() {
      const { NetworkPage } = await import('../pages/network');
      return {
        Component: () =>
          authWrapper(<NetworkPage />, 'auth', superAdminAccessRoles)
      };
    },
    children: networkRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.users.root,
    async lazy() {
      const { UsersPage } = await import('../pages/users');
      return {
        Component: () =>
          authWrapper(<UsersPage />, 'auth', superAdminAccessRoles)
      };
    },
    children: usersRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.agents.root,
    async lazy() {
      const { AgentsPage } = await import('../pages/agents');
      return {
        Component: () =>
          authWrapper(<AgentsPage />, 'auth', AgencyAdminAccessRoles)
      };
    },
    children: agentsRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: '/form-builder',
    async lazy() {
      const { FormBuilderPage } = await import('../pages/FormBuilder');
      return { Component: () => authWrapper(<FormBuilderPage />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: '/success-payment/quote/:applicationId/:submissionResponseId/:paymentMethod',
    async lazy() {
      const { PaymentSuccessPage } = await import('../pages/PaymentSuccess');
      return { Component: () => authWrapper(<PaymentSuccessPage />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: '/verify-application',
    async lazy() {
      const { VerifyApplicationPage } = await import('../pages/verify');
      return { Component: () => <VerifyApplicationPage /> };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: '/mga-app-approval',
    async lazy() {
      const { ApprovalPage } = await import('../pages/approval');
      return { Component: () => <ApprovalPage /> };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: '/upload-policy',
    async lazy() {
      const { ApprovalPage } = await import('../pages/approval');
      return { Component: () => <ApprovalPage /> };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.documentTemplate.root,
    async lazy() {
      const { TemplatesPage } = await import('../pages/documentTemplate');
      return {
        Component: () =>
          authWrapper(<TemplatesPage />, 'auth', superAdminAccessRoles)
      };
    },
    children: documentTemplateRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  }
]);
