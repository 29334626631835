import React from 'react';

import { suspenseWrapper } from 'utils/suspenseWrapper';

export const distributionRoute = [
  {
    path: '',
    async lazy() {
      const { Distribution } = await import('../pages/distribution/Distribution/Distribution');
      return {Component: () => suspenseWrapper(<Distribution />)};
    }
  },

  {
    path: 'binding',
    async lazy() {
      const { Bindings } = await import('../pages/distribution/Binding/Binding');
      return {Component: () => suspenseWrapper(<Bindings />)};
    }
  },
  {
    path: 'policy',
    async lazy() {  
      const { Policies } = await import('../pages/distribution/Policy/Policy');
      return {Component: () => suspenseWrapper(<Policies />)};
    }
  },
  {
    path: 'mga-submission',
    async lazy() {
      const { MgaSubmission } = await import('../pages/distribution/MgaSubmission/MgaSubmission');
      return {Component: () => suspenseWrapper(<MgaSubmission />)};
    }
  },
  {
    path: 'payment',
    async lazy() {
      const { Payments } = await import('../pages/distribution/Payment/Payment');
      return {Component: () => suspenseWrapper(<Payments />)};
    }
  },
  {
    path: 'application-forms',
    async lazy() {
      const { ApplicationForms } = await import('../pages/distribution/ApplicationForms/ApplicationForms');
      return {Component: () => suspenseWrapper(<ApplicationForms />)};
    }
  },
  {
    path: 'quote-indication',
    async lazy() {
      const { QuoteIndication } = await import('../pages/distribution/QuoteIndication/QuoteIndication');
      return {Component: () => suspenseWrapper(<QuoteIndication />)};
    }
  }
];
