import React from 'react';

import { suspenseWrapper } from 'utils/suspenseWrapper';

export const carriersRoute = [
  {
    path: '',
    async lazy() {
      const  { Carriers } = await import("../pages/carriers/All/Carriers");
      return { Component: () => suspenseWrapper(<Carriers />) };
    }
  }
];
