export const API_KEY = 'bac87873a53869e58c0622ac74e9015046084d4f';
export const API_BASE_URL = 'https://uat-api.squarerisk.net/';
export const SOCKET_BASE_URL = 'wss://uat-api.squarerisk.net';
export const SOCKET_SITE_PATH = '/socket';
export const S3_BUCKET_NAME = 'square-risk-uat-files';
export const S3_BUCKET_REGION = 'ap-south-1';
export const S3_BUCKET_COMPANY_LOGO_PATH = 'company/logo';
export const S3_BUCKET_COMPANY_FILE_PATH = 'company-product/policies';
export const S3_BUCKET_COMPAPY_DOCUMENTS_PATH = 'company/documents';
export const S3_BUCKET_COMPANY_DOCUMENTS_PATH = 'company/documents';
export const S3_BUCKET_FILE = 'pre-signature-templates';

export const S3_BUCKET_ACCESS_KEY = 'AKIA3OKR7TQRENMDSWUB';
export const S3_BUCKET_SECRET_ACCESS_KEY = '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';

export const STRIPE_API_KEY =
  'pk_test_51MUP6qKwlwY6ryZJYEcGfTQEPb1akj8LRlAWzJkfOQRlQPMfOZLYpZEcBaUPs9U1Q2hX8xKisgyMKD5HApo5RJJh00NqUMoyNh';
