import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';

export function FullModalTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ p: 3, boxShadow: '0 2px 5px #ccc' }} {...other}>
      <Box sx={{ marginRight: '55px', maxWidth: '1050px' }}>{children}</Box>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 22,
            top: 22,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon sx={{ fontSize: 32 }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

FullModalTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};
