import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const applicationApi = createApi({
  reducerPath: 'applicationApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    
    getApplications: builder.query({
      query: (payload) => ({
        url: `/app-template/get-list?limit=${
          payload.limit === -1 ? 200 : payload.limit
        }&offset=${payload.offset}&currentTab=${payload.currentTab}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getApplicationsByProductId: builder.query({
      query: (payload) => {
        let url = `/app-template/get-list?limit=${payload.limit === -1 ? 200 : payload.limit}&offset=${payload.offset}`;
    
        if (payload.currentTab) {
          url += `&currentTab=${payload.currentTab}`;
        }
    
        if (payload.productId) {
          url += `&productId=${payload.productId}`;
        }
    
        return {
          url,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'X-API-KEY': API_KEY,
            Authorization: `Bearer ${payload.token}`
          }
        };
      }
    }),
    
    ApplicationDetail: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/app-template/get?applicationTemplateId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    addApplication: builder.mutation({
      query: (payload) => ({
        url: '/app-template/add',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    getPaymentMethods: builder.mutation({
      query: (payload) => ({
        url: '/application/get-payment-method-options',
        method: 'POST',
        body: {applicationId:payload.applicationId},
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    
    verfiyCarrierPaymentDetails: builder.mutation({
      query: (payload) => ({
        url: '/payment/verify-carrier-payment-details',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    getCarrierPaymentPlans: builder.query({
      query: (payload) => ({
        url: `/payment/get-carrier-payment-plans?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),


    updateApplication: builder.mutation({
      query: (payload) => ({
        url: '/app-template/update',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    })
  })
});

export const {
  useAddApplicationMutation,
  useGetApplicationsQuery,
  useApplicationDetailQuery,
  useUpdateApplicationMutation,
  useGetPaymentMethodsMutation,
  useGetCarrierPaymentPlansQuery,
  useVerfiyCarrierPaymentDetailsMutation,
  useGetApplicationsByProductIdQuery
} = applicationApi;
