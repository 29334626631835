import * as React from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Products from 'assets/nav-icons/products.svg';
import ProductsActive from 'assets/nav-icons/products-active.svg';
import Agencies from 'assets/nav-icons/agencies.svg';
import AgenciesActive from 'assets/nav-icons/agencies-active.svg';
import MGAs from 'assets/nav-icons/mgas.svg';
import MGAsActive from 'assets/nav-icons/mgas-active.svg';
// import CarrierActive from 'assets/nav-icons/carrier-active.svg';
// import Carrier from 'assets/nav-icons/carrier.svg';
import DocumentTextActive from 'assets/nav-icons/document-text-active.svg';
import DocumentTextInActive from 'assets/nav-icons/document-text-inactive.svg';
import DistributionActive from 'assets/nav-icons/distribution_active.svg';
import Distribution from 'assets/nav-icons/distribution.svg';
import Quote from 'assets/nav-icons/quotes.svg';
import QuoteActive from 'assets/nav-icons/quotes-active.svg';
// import Updates from 'assets/nav-icons/updates.svg';
// import UpdatesActive from 'assets/nav-icons/updates-active.svg';
import Users from 'assets/nav-icons/users.svg';
import UsersActive from 'assets/nav-icons/users-active.svg';
import { useAuth } from 'hooks/useAuth';

import { path } from '../../constant/pathLinksConstant';

const navLinkStyle = { borderRadius: '10px' };

export function NavBar() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const pathname = window.location.pathname.split('/')[1];
  const isMatch = (url) => `/${pathname}` === url;

  const navAgencyEmp = [
    {
      title: t('navigation.quote'),
      icon: Quote,
      iconActive: QuoteActive,
      link: path.quote.root
    }
    // {
    //   title: t('navigation.network'),
    //   icon: Updates,
    //   iconActive: UpdatesActive,
    //   link: path.network.root,
    // },
  ];

  const navAgencyAdmin = [
    ...navAgencyEmp,
    {
      title: 'Users',
      icon: Users,
      iconActive: UsersActive,
      link: path.agents.root
    }
  ];

  const navSuperAdmin = [
    {
      title: t('navigation.distribution'),
      icon: Distribution,
      iconActive: DistributionActive,
      link: path.distribution.root
    },
    {
      title: t('navigation.product'),
      icon: Products,
      iconActive: ProductsActive,
      link: path.products.root
    },
    {
      title: t('navigation.partners'),
      icon: Agencies,
      iconActive: AgenciesActive,
      link: path.agencies.root
    },
    {
      title: t('navigation.mga'),
      icon: MGAs,
      iconActive: MGAsActive,
      link: path.mgas.root
    },
    // {
    //   title: t('navigation.carriers'),
    //   icon: Carrier,
    //   iconActive: CarrierActive,
    //   link: path.carriers.root
    // },
    {
      title: t('navigation.user'),
      icon: Users,
      iconActive: UsersActive,
      link: path.users.root
    },
    {
      title: t('navigation.documentTemplate'),
      icon: DocumentTextInActive,
      iconActive: DocumentTextActive,
      link: path.documentTemplate.root
    }
  ];

  let navigation;

  switch (user.role.slug) {
    case 'PORTAL_ADMIN':
    case 'PORTAL_EMPLOYEE':
    case 'MGA_ADMIN':
    case 'MGA_EMPLOYEE':
    case 'AGENCY_CUSTOMER':
    case 'CUSTOMER_USER':
      navigation = navSuperAdmin;
      break;

    case 'AGENCY_ADMIN':
      navigation = navAgencyAdmin;
      break;
    case 'AGENCY_EMPLOYEE':
    default:
      navigation = navAgencyEmp;
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{ marginBottom: '0.5rem', fontWeight: 700 }}
      >
        MAIN MENU
      </Typography>
      <List>
        {navigation.map((nav) => (
          <ListItem key={`key--${nav.title}`} disablePadding>
            <ListItemButton
              onClick={() => navigate(nav.link)}
              selected={isMatch(nav.link)}
              sx={navLinkStyle}
            >
              <ListItemIcon sx={{ minWidth: '35px' }}>
                {isMatch(nav.link) ? (
                  <img src={nav.iconActive} alt={nav.title} />
                ) : (
                  <img src={nav.icon} alt={nav.title} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: isMatch(nav.link) ? '800' : '200' }}
                  >
                    {nav.title}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}
