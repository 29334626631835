import { useRef } from 'react';

import { generateUniqueKey } from 'utils/generateUniqueKey';

export function useGetUniqueKey() {
  const uniqueKeysRef = useRef(new Map());

  function getUniqueKey(item) {
    if (uniqueKeysRef.current.has(item)) {
      return uniqueKeysRef.current.get(item);
    }

    const uniqueKey = generateUniqueKey();
    uniqueKeysRef.current.set(item, uniqueKey);
    return uniqueKey;
  }

  return getUniqueKey;
}
