import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const resetPasswordApi = createApi({
  reducerPath: 'resetPasswordApi',
  tagTypes: ['Post'],
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    postSetPassword: builder.mutation({
      query: (payload) => ({
        url: '/user/set-user-password',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    getToken: builder.mutation({
      query: (payload) => ({
        url: '/user/verify-user-email',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
        }
      }),
      invalidatesTags: ['Post']
    })
  })
});

export const { useGetTokenMutation, usePostSetPasswordMutation } =
  resetPasswordApi;
