import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grow from '@mui/material/Grow';

import AnimatedLoader from 'components/Loader/AnimatedLoader';

import { ModalTitle } from './ModalTitle';
// import Animation from '../../assets/animation.gif';

const CustomModal = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: 0
  },
  '& .MuiDialogActions-root': { padding: theme.spacing(3) },
  '& .MuiPaper-root': { borderRadius: 8 }
}));

const Transition = React.forwardRef((props, ref) => (
  <Grow ref={ref} {...props} />
));

export function Modal({
  open,
  onClose,
  title,
  content,
  action,
  isLoading,
  ...props
}) {
  return (
    <CustomModal
      onClose={onClose}
      TransitionComponent={Transition}
      open={open}
      {...props}
    >
      {isLoading ? (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            height: 400,
            width: '100%'
          }}
        >
          <AnimatedLoader />
        </div>
      ) : (
        <>
          <ModalTitle id="customized-dialog-title" onClose={onClose}>
            {title}
          </ModalTitle>
          <DialogContent>{content}</DialogContent>
          <DialogActions>{action}</DialogActions>
        </>
      )}
    </CustomModal>
  );
}
