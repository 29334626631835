import React from 'react';

import { suspenseWrapper } from 'utils/suspenseWrapper';


export const quotesRoute = [
  {
    path: '',
    async lazy() {
      const  { Quotes } = await import("../pages/quotes/All/Quote");
      return { Component: () => suspenseWrapper(<Quotes />) };
    }
  },
  {
    path: 'create',
    async lazy() {
      const  { CreateQuote } = await import("../pages/quotes/All/CreateQuote");
      return { Component: () => suspenseWrapper(<CreateQuote />) };
    }
  },
  {
    path: 'edit',
    async lazy() {
      const  { CreateQuote } = await import("../pages/quotes/All/CreateQuote");
      return { Component: () => suspenseWrapper(<CreateQuote />) };
    }
  }
];
