import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const shareApplicationApi = createApi({
  reducerPath: 'shareApplicationApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getShareApplication: builder.query({
      keepUnusedDataFor: 30,
      query: (payload) => ({
        url: `/application/share-application/get-share-application-form-customer-template?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    shareToCustomer: builder.mutation({
      query: (payload) => ({
        url: '/application/share-application/share-application-form-customer',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateCurrentEditor: builder.mutation({
      query: (payload) => ({
        url: '/application/share-application/update-current-editor',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    })
  })
});

export const {
  useGetShareApplicationQuery,
  useShareToCustomerMutation,
  useUpdateCurrentEditorMutation
} = shareApplicationApi;
