import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const distributionsApi = createApi({
  reducerPath: 'distributionsApi',
  tagTypes: ['POST'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getDistributionList: builder.query({
      query: (payload) => ({
        url: `/distribution/get?limit=${payload.limit}&offset=${payload.offset}&selectedSearch=${payload.filterBy}&search=${payload.searchBy}`,

        // `/distribution/get?limit=${payload.limit}&offset=${
        //   payload.offset
        // }${payload.filterBy ? `&selectedSearch=${payload.filterBy}` : ''}${
        //   payload.searchBy ? `&search=${payload.searchBy}` : ''
        // }`
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getBindingList: builder.query({
      query: (payload) => ({
        url: `/distribution/get-app-bind-data?applicationId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getIndicationCarriers: builder.query({
      query: (payload) => ({
        url: `/distribution/get-indications-carrier?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    referToMgaApplication: builder.query({
      query: (payload) => ({
        url: `/distribution/pre-refer-to-mga?applicationId=${payload.id}&mgaName=${payload.mgaName}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    updateAuthorizeApplication: builder.mutation({
      query: (payload) => ({
        url: '/distribution/authorize-application',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    updateDistributionReferMga: builder.mutation({
      query: (payload) => ({
        url: '/distribution/refer-to-mga',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateAdditionalInfo: builder.mutation({
      query: (payload) => ({
        url: '/distribution/request-additional-info',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateMgaApproval: builder.mutation({
      query: (payload) => ({
        url: '/distribution/mga-approval',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    getMgaScreenInfo: builder.query({
      query: (payload) => ({
        url: `/distribution/get-mga-screen-info?applicationId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getPolicyList: builder.query({
      query: (payload) => ({
        url: `/distribution/get-policy-info?applicationId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getPolicyEmail: builder.query({
      query: (payload) => ({
        url: `/distribution/get-policy-request-email?applicationId=${payload.id}&mgaName=${payload.mgaName}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    sendPolicyRequestEmail: builder.mutation({
      query: (payload) => ({
        url: '/distribution/send-policy-request-email',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    getMgaSubmission: builder.query({
      query: (payload) => ({
        url: `/distribution/get-mga-submissions?limit=${payload.limit}&offset=${payload.offset}&applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getDistributionPayment: builder.query({
      query: (payload) => ({
        url: `/payment/get-distribution-payment-list?applicationId=${payload.applicationId}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getApplicationForms: builder.query({
      query: (payload) => ({
        url: `/distribution/get-app-forms?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getQuoteIndication: builder.query({
      query: (payload) => ({
        url: `/distribution/get-indications?applicationId=${payload.applicationId}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getQuoteIndicationDetail: builder.query({
      query: (payload) => ({
        url: `/distribution/get-indication-detail?submissionResponseId=${payload.submissionResponseId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    addQuoteIndicationDetail: builder.mutation({
      query: (payload) => ({
        url: `/distribution/add-indications`,
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['POST']
    }),
    updateQuoteIndicationDetail: builder.mutation({
      query: (payload) => ({
        url: `/distribution/update-indications`,
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    refreshQuote: builder.mutation({
      query: (payload) => ({
        url: `/distribution/refresh-quote`,
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    })
  })
});
export const {
  useGetDistributionListQuery,
  useGetBindingListQuery,
  useReferToMgaApplicationQuery,
  useUpdateAuthorizeApplicationMutation,
  useUpdateDistributionReferMgaMutation,
  useSendPolicyRequestEmailMutation,
  useUpdateAdditionalInfoMutation,
  useUpdateMgaApprovalMutation,
  useGetMgaScreenInfoQuery,
  useGetPolicyListQuery,
  useGetPolicyEmailQuery,
  useGetMgaSubmissionQuery,
  useGetDistributionPaymentQuery,
  useGetApplicationFormsQuery,
  useGetQuoteIndicationQuery,
  useGetQuoteIndicationDetailQuery,
  useUpdateQuoteIndicationDetailMutation,
  useRefreshQuoteMutation,
  useAddQuoteIndicationDetailMutation,
  useGetIndicationCarriersQuery
} = distributionsApi;
